import { IcebergButton, useSelectedTsKunde } from "@tradesolution/iceberg-ui-react";
import { toPng } from "html-to-image";
import { useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { dataURItoBlob } from "../../Shared/FileHelper";
import PicassoApi from "../../Services/PicassoApi";
import ProductTable, { ProductData } from "../../components/ProductTable";
import { generateBarcode } from "../../Shared/BarcodeGenerator";

const TableGenerator = () => {
    // State for å lagre rader i tabellen (produktdata)
    const [tableData, setTableData] = useState<ProductData[]>([]);

    // Ref for å referere til tabell-elementet, slik at vi kan konvertere det til et bilde
    const tableRef = useRef<HTMLDivElement>(null);


    const [productName, setProductName] = useState<string>('');
    const [epdNr, setEpdNr] = useState<string>('');
    const { selectedTsKunde } = useSelectedTsKunde();

    const addProduct = (event: any): void => {
        setTableData([...tableData, { produktnavn: productName, epdNr: epdNr, basegtins: [], strekkodeBase64Url: generateBarcode(epdNr), unitSmallPreviewImageGuid: undefined, angle: undefined }]);
    }

    const [dataurl, setDataurl] = useState<string>('');

    //Funksjon for å generere bilde fra tabellen:
    const generateImage = () => {
        if (tableRef.current) {
            toPng(tableRef.current, { backgroundColor: 'white' })
                .then((dataUrl) => {
                    setDataurl(dataUrl);
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'table-image.png';
                    link.click();
                })
                .catch((err) => {
                    console.error('Failed to generate image:', err);
                });
        }
    };

    const removeProduct = (row: ProductData): void => {
        setTableData(tableData.filter((product) => product !== row));
    }

    const [tableImageUrl, setTableImageUrl] = useState<string>();
    const uploadToServer = async () => {
        const blob = dataURItoBlob(dataurl);
        const response = await PicassoApi.files.postBlob(blob, selectedTsKunde?.tsKundeId);
        setTableImageUrl(response.urlWithToken);
    }

    return (
        <>
            <Row>
                <Col>
                    <h3>TableGenerator</h3>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h4>For visning i designer</h4>
                    <div>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Produktnavn</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.produktnavn}</td>
                                        <td className="text-end">
                                            <IcebergButton variant='outline-danger' icon='trash' onClick={() => removeProduct(row)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="mt-2">
                            <Form.Group>
                                <Form.Label>Produktnavn</Form.Label>
                                <Form.Control value={productName} onChange={(e: any) => setProductName(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>EpdNr</Form.Label>
                                <Form.Control value={epdNr} onChange={(e: any) => setEpdNr(e.target.value)} />
                            </Form.Group>
                        </div>
                        <div className="mt-2">
                        <IcebergButton icon='plus' variant='outline-primary' onClick={addProduct}>Add product</IcebergButton>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className="mt-3">
                        <h4>For generering av bilde</h4>
                        <div ref={tableRef}>
                            <ProductTable tableData={tableData} />
                        </div>

                        <IcebergButton variant="outline-primary" onClick={generateImage} icon="send" className="mt-3">
                            Generate Image
                        </IcebergButton>
                        <br />
                        <Form.Control value={dataurl} readOnly className="mt-2" /><br />
                        <IcebergButton variant="outline-primary" onClick={uploadToServer} icon="send" className="mt-2">
                            Upload to server
                        </IcebergButton>
                        <br />
                        {tableImageUrl && <img src={tableImageUrl} alt="table" />}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>

                </Col>
            </Row>
        </>
    );
}

export default TableGenerator;