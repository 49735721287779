import { useEffect, useState } from "react";
import { useSelectedTsKunde } from "@tradesolution/iceberg-ui-react";
import PicassoApi from "../../../../Services/PicassoApi";

interface Props {
    logoFileName: string | undefined;
    logoText: string | undefined;
    fontFamily: string;
    fontColor: string;
}

const Logo = (props: Props) => { 
    const {selectedTsKunde } = useSelectedTsKunde();

    const [logoBlob, setLogoBlob] = useState<Blob | null>(null);

    const getLogo = async (fileName: string) => {
        const logoBlob = await PicassoApi.logos.getLogo(fileName, selectedTsKunde?.tsKundeId);
        if(logoBlob){
            setLogoBlob(logoBlob);
        }  
      };

    useEffect(() => {
        if (props.logoFileName) {
            getLogo(props.logoFileName);
        }
        else {
            setLogoBlob(null);
        }
    }, [props.logoFileName]);

    return (
        <div>
            <div className="template-logo-placeholder mt-5">
                {logoBlob && <img id="logo" src={URL.createObjectURL(logoBlob)} style={{ maxWidth: "200px", maxHeight: "100px" }} />}
                {!logoBlob && <h1 style={{ fontFamily: props.fontFamily, color: props.fontColor ?? "#000" }}>{props.logoText}</h1>}
            </div>
        </div>        
    );
}

export default Logo;