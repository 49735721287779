import { useEffect, useState } from "react";
import { Button } from "react-bootstrap"
import MediastoreMediaApi from "../../../../../Services/MediastoreMediaApi";
import { ImageSetResponse, ProductImage } from "../../../../../Services/MediastoreMediaApi/models";

interface Props {
  gtin: string;
  onAngleSelected: (angle: string) => void;
}

const ProductAngleSelector = (props: Props) => {
  const [imagesetResponse, setImagesetResponse] = useState<ImageSetResponse | null>(null);

  const loadImageInfoFromMediastoreMedia = async (gtin: string) => {
    const response = await MediastoreMediaApi.imageset.getImageSetByGtin(gtin);
    if (response) {
      setImagesetResponse(response);
      setSelectedImage(response[0].productImages[0]);
    }else {
        setImagesetResponse(null);
    }
  }

  useEffect(() => {
    loadImageInfoFromMediastoreMedia(props.gtin);
  }, [props.gtin]);

  const [selectedImage, setSelectedImage] = useState<ProductImage | null>(null);

  // Handle angle change
  const handleAngleChange = (direction: "prev" | "next") => {

    if (!imagesetResponse || !selectedImage) return;

    const imageset = imagesetResponse[0];

    const currentIndex = imageset.productImages.findIndex(
      (img) => img.angle === selectedImage.angle
    );
    const newIndex =
      direction === "next"
        ? (currentIndex + 1) % imageset.productImages.length
        : (currentIndex - 1 + imageset.productImages.length) % imageset.productImages.length;

        const newImage = imageset.productImages[newIndex];
    setSelectedImage(newImage);
    props.onAngleSelected(newImage.angle);
  };

  return (
    <div className="d-flex flex-column align-items-center p-2 border rounded">
      {imagesetResponse && (
        <div className="text-center">
          {/* Template placeholder with Bootstrap styling */}
          <div className="mb-2">
            <img style={{maxWidth: "100px", maxHeight: "100px"}} src={"https://mediastore.tradesolution.no/download/preview/" + selectedImage?.previewSmallBlobId} />
          </div>
          {/* Image name dropdown */}
          {/* <Form.Select
            value={selectedImage?.OriginalFileName || ""}
            onChange={(e) => handleImageSelect(e.target.value)}
            className="mb-2"
            style={{ width: "100%" }}
          >
            {productInfo.Images.map((image) => (
              <option key={image.ImageSetID} value={image.OriginalFileName}>
                {image.OriginalFileName}
              </option>
            ))}
          </Form.Select> */}

          {/* Angle Display and Navigation */}
          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => handleAngleChange("prev")}
              className="me-1"
            >
              &lt;
            </Button>
            <span>{selectedImage?.angle || "N/A"}</span>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => handleAngleChange("next")}
              className="ms-1"
            >
              &gt;
            </Button>
          </div>
        </div>)}
    </div>
  );
};

export default ProductAngleSelector;
