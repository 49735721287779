import { useState } from 'react';
import ReactDOM from 'react-dom';
import { generateBarcode } from '../../Shared/BarcodeGenerator';

const BarcodeCreator = () => {
  const [inputValue, setInputValue] = useState(''); // State to track the input field value
  const [base64Url, setBase64Url] = useState(''); // State to store the generated barcode image URL

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Barcode Generator</h1>
      
      {/* Input field to enter the barcode value */}
      <input 
        type="text" 
        placeholder="Enter barcode value" 
        value={inputValue} 
        onChange={(e) => setInputValue(e.target.value)}
        style={{ padding: '10px', fontSize: '16px' }}
      />

      {/* Button to generate barcode */}
      <button 
        onClick={() => setBase64Url(generateBarcode(inputValue))} 
        style={{ marginLeft: '10px', padding: '10px 20px', fontSize: '16px' }}
      >
        Add
      </button>

      <div style={{ marginTop: '20px' }}>
        <h2>Generated Barcode</h2>
        {/* SVG element where barcode will be displayed */}
        {base64Url && <img src={base64Url} alt="barcode" />}
      </div>
    </div>
  );
};

ReactDOM.render(<BarcodeCreator />, document.getElementById('root'));

export default BarcodeCreator;