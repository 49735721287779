import axios from "axios";
import { ProductResponse, Rfc9110Response } from "./Models";

const baseUrl = "https://mediastore.tradesolution.no/api";

const getCachedBlob = async (key: string) => {
    const cache = await caches.open('blob-cache');
    const response = await cache.match(key);
    if (!response) return null;
    const blob = await response.blob();
    return blob;
};

//TODO: Invalidate cache if needed. Maybe cache only 100 images and delete the oldest? Or delete cache entries older than a limit
const setCachedBlob = async (key: string, blob: Blob) => {
    const cache = await caches.open('blob-cache');
    const response = new Response(blob);
    await cache.put(key, response);
};

const MediastoreApi = {
    products: {
        getById: async (gtin: string) => {
            let response;
            try {
                const url = `${baseUrl}/Products/${gtin}`;
                response = (await axios.get<ProductResponse | Rfc9110Response>(url));
            } catch (error: any) {
                response = error;
            }
            return response;
        },
        getMainImage: async (gtin: string): Promise<Blob | undefined> => {
            const url = `${baseUrl}/Products/${gtin}/main/image?size=752png&compressed=false`;

            var blob = await getCachedBlob(url);

            if (blob) {
                return blob;
            }

            const response = await axios<Blob>(url, { responseType: 'blob' });

            if (response.data instanceof Blob) {
                setCachedBlob(url, response.data);
            }

            return response.data;
        },
        getAngleImage: async (gtin: string, angle: string): Promise<Blob | undefined> => {
            const url = `${baseUrl}/Products/${gtin}/${angle}/image?size=752png&compressed=false`;

            var blob = await getCachedBlob(url);

            if (blob) {
                return blob;
            }
            const response = await axios<Blob>(url, { responseType: 'blob' });

            if (response.data instanceof Blob) {
                setCachedBlob(url, response.data);
            }
            return response.data;
        }
    },
    token: {
        getApiToken: async (): Promise<string> => {
            const url = `${baseUrl}/Products/getApiToken`;
            return (await axios.get<string>(url)).data;
        }
    },

}

export default MediastoreApi;
