import { Accordion, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { IcebergButton, IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { useState } from "react";

interface Props {
}

const CustomDesignSection = (props: Props) => {

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const openModal = () => setShowModal(true);

    const sendRequest = (): void => {
        console.log("Function not implemented.");
    }

    return (
        <>
            <Accordion defaultActiveKey="0" className='mt-3'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><IcebergIcon icon="cartridge" className='me-2' /> Tilpass din grafiske profil - Ta kontakt</Accordion.Header>
                    <Accordion.Body>
                        <Card>
                            <Card.Img variant="top" src="/img/CustomDesign.png" />
                            <Card.Body>
                                <Card.Text>
                                    Motta tilbud på skreddersydd design
                                </Card.Text>
                                <IcebergButton onClick={openModal} className="w-100 mt-3" variant="outline-primary">Vis meg mer info</IcebergButton>
                            </Card.Body>
                        </Card>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Innsalgsark tilpasset din grafiske profil</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <img src="/img/CustomDesignHeader.png" alt="Custom design" className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Card>
                                <Card.Img variant="top" src="/img/CustomDesignPreview1.png" />
                                <Card.Body>
                                    <Card.Text>
                                        Eksempel design 1
                                    </Card.Text>
                                    <IcebergButton className="w-100 mt-3" variant="outline-primary">Forhåndsvis</IcebergButton>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src="/img/CustomDesignPreview2.png" />
                                <Card.Body>
                                    <Card.Text>
                                        Eksempel design 2
                                    </Card.Text>
                                    <IcebergButton className="w-100 mt-3" variant="outline-primary">Forhåndsvis</IcebergButton>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Img variant="top" src="/img/CustomDesignPreview3.png" />
                                <Card.Body>
                                    <Card.Text>
                                        Eksempel design 3
                                    </Card.Text>
                                    <IcebergButton className="w-100 mt-3" variant="outline-primary">Forhåndsvis</IcebergButton>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <p>Fyll ut din kontakt informasjon så kontakter vi deg</p>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Control placeholder="Navn" />
                                </Col>
                                <Col>
                                    <Form.Control placeholder="Epost" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <IcebergButton variant="outline-primary" icon="close" className="col" onClick={handleCloseModal}>
                        Avbryt
                    </IcebergButton>
                    <IcebergButton variant="primary" icon="send" className="col" onClick={sendRequest}>
                        Send
                    </IcebergButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomDesignSection;