import { IcebergButton, IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import ProduktModal from "../ProduktModal";
import { ProductData } from "../../../../components/ProductTable";
import ProductImageSelector from "./ProductImageSelector";

interface Props {
    onTemplateSelected: (o: Template) => void;
    onProductDataChanged: (data: ProductData[]) => void;
    onAngleSelected: (gtin: string, angle: string) => void;
    productData: ProductData[];
}

export interface Template {
    name: string;
    id: number;
    imageCount: number;
    maxProductCount: number;
    thumbnailUrl?: string;
}

export const Templates: Template[] = [
    { name: 'Template 1', id: 1, imageCount: 1, maxProductCount: 4, thumbnailUrl: "/img/template1.png" },
    { name: 'Template 2', id: 2, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template2.png" },
    { name: 'Template 3', id: 3, imageCount: 4, maxProductCount: 4, thumbnailUrl: "/img/template3.png" },
    { name: 'Template 4', id: 4, imageCount: 2, maxProductCount: 4, thumbnailUrl: "/img/template4.png" },
    { name: 'Template 5', id: 5, imageCount: 2, maxProductCount: 4, thumbnailUrl: "/img/template5.png" },
    { name: 'Template 6', id: 6, imageCount: 2, maxProductCount: 4, thumbnailUrl: "/img/template6.png" },
    { name: 'Template 7', id: 7, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template7.png" },
    { name: 'Template 8', id: 8, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template8.png" },
    { name: 'Template 9', id: 9, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template9.png" },
    { name: 'Template 10', id: 10, imageCount: 4, maxProductCount: 4, thumbnailUrl: "/img/template10.png" },
    { name: 'Template 11', id: 11, imageCount: 4, maxProductCount: 4, thumbnailUrl: "/img/template11.png" },
    { name: 'Template 12', id: 12, imageCount: 0, maxProductCount: 4, thumbnailUrl: "/img/template12.png" },
];

const LeftCol = (props: Props) => {
    const [selectedTemplate, setSelectedTemplate] = useState<Template>(Templates.find(o => o.id === 1) as Template);


    const handleTemplateSelected = (o: Template): void => {
        setSelectedTemplate(o);
        props.onTemplateSelected(o);
    }

    const handleProductsSaved = async (products: ProductData[]): Promise<void> => {
        props.onProductDataChanged(products);
    }

    const removeProduct = (row: ProductData): void => {
        const remaningProducts = props.productData.filter((product) => product !== row);
        props.onProductDataChanged(remaningProducts);
    }

    const handleAngleSelected = (gtin: string, angle: string): void => {
        props.onAngleSelected(gtin, angle);
    }

    return (
        <div>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><IcebergIcon icon="document" className='me-2' /> Velg mal</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <div className="row row-cols-3 g-3">
                                {Templates.map((o) => (
                                    <div className="col" key={o.id}>
                                        <div
                                            className={`p-2 border ${selectedTemplate?.id === o.id ? "border-primary border-3" : "border-secondary"}`}
                                            style={{ cursor: "pointer", borderRadius: "5px" }}
                                            onClick={() => handleTemplateSelected(o)}
                                        >
                                            <img
                                                src={o.thumbnailUrl}
                                                alt={`Template ${o.id}`}
                                                className="img-fluid"
                                                style={{ width: "100%", height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {/*Table Content*/}
            <Accordion defaultActiveKey="0" className="mt-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <IcebergIcon icon="table" className="me-2" /> Tabell
                    </Accordion.Header>
                    <Accordion.Body>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Produktnavn</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.productData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.produktnavn}</td>
                                        <td className="text-end">
                                            <IcebergButton variant='outline-danger' icon='trash' onClick={() => removeProduct(row)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/*Modal */}
                        <ProduktModal onSave={handleProductsSaved} selectedProducts={props.productData} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ProductImageSelector gtins={props.productData.map(o => o.basegtins[0])} template={selectedTemplate} onAngleSelected={handleAngleSelected} />
        </div>
    )
}

export default LeftCol;