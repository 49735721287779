import { IcebergButton, IconButton } from '@tradesolution/iceberg-ui-react';
import React, { useState } from 'react';
import { ButtonProps } from 'react-bootstrap';

interface Props extends ButtonProps {
}

const ConfirmButton = (props: Props) => {
  const [timesPressed, setTimesPressed] = useState(0);
  const [children, setChildren] = useState(props.children);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (timesPressed === 0) {
      setChildren('Sikker?');
      setTimesPressed(z => z + 1);
    } else {
        if (props.onClick) {
            props.onClick(e);
            setChildren(props.children);
            setTimesPressed(0);
        }      
    }
  }

  return (
    <IcebergButton
      {...props}
      onClick={handleClick}
      icon="trash">
      {children}
    </IcebergButton>
  );
};

export default ConfirmButton;
