import axios from "axios";

const baseUrl = "https://picassoapi.tradesolution.no/api";

export interface ProductSearchResponse {
    items: ProductInfo[]
}

export interface ProductInfo {
    identificationNumber: string
    versions: Version[]
}

export interface Version {
    phase: string
    unitSmallPreviewImageGuids: string[]
    orderableUnitGtin: string
    supplierCompanyName: string
    variantName: string
    gtins: string[]
}

export interface UploadedFileResponse {
    sasToken: string
    url: string
    urlWithToken: string
}

export interface LogoDto {
    fileName: string
}

export interface BackgroundImageDto {
    fileName: string
}

export default {
    files: {
        post: async (file: File, tsKundeId: string): Promise<UploadedFileResponse> => {
            const formData = new FormData();
            formData.append("file", file);

            return (await axios.post(`${baseUrl}/Files?tsKundeId=${tsKundeId}`, formData)).data;
        },
        postBlob: async (file: Blob, tsKundeId: string): Promise<UploadedFileResponse> => {
            const formData = new FormData();
            formData.append("file", file);
            return (await axios.post(`${baseUrl}/Files?tsKundeId=${tsKundeId}`, formData)).data;
        }
    },
    productSearch: {
        get: async (query: string, tsKundeId: string): Promise<ProductSearchResponse> => {
            return (await axios.get(`${baseUrl}/ProductSearch?query=${query}&tsKundeId=${tsKundeId}`)).data;
        }
    },
    logos: {
        get: async (tsKundeId: string): Promise<LogoDto[]> => {
            return (await axios.get(`${baseUrl}/Logos?tsKundeId=${tsKundeId}`)).data;
        },
        getLogo: async (fileName: string, tsKundeId: string): Promise<Blob | null> => {
            try{
                const response = await axios.get(`${baseUrl}/Logos/${fileName}?tsKundeId=${tsKundeId}`, {
                    responseType: 'blob' // This allows handling binary data like images
                });
                return response.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
        post: async (file: File, tsKundeId: string): Promise<LogoDto> => {
            const formData = new FormData();
            formData.append("file", file);
            return (await axios.post(`${baseUrl}/Logos?tsKundeId=${tsKundeId}`, formData)).data;
        },
        delete: async (fileName: string, tsKundeId: string) => {
            await axios.delete(`${baseUrl}/Logos/${fileName}?tsKundeId=${tsKundeId}`);
        },
    },
    bacgroundImages: {
        get: async (tsKundeId: string): Promise<BackgroundImageDto[]> => {
            return (await axios.get(`${baseUrl}/BackgroundImages?tsKundeId=${tsKundeId}`)).data;
        },
        getBackgroundIamge: async (fileName: string, tsKundeId: string): Promise<Blob | null> => {
            try{
                const response = await axios.get(`${baseUrl}/BackgroundImages/${fileName}?tsKundeId=${tsKundeId}`, {
                    responseType: 'blob' // This allows handling binary data like images
                });
                return response.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
        post: async (file: File, tsKundeId: string): Promise<BackgroundImageDto> => {
            const formData = new FormData();
            formData.append("file", file);
            return (await axios.post(`${baseUrl}/BackgroundImages?tsKundeId=${tsKundeId}`, formData)).data;
        },
        delete: async (fileName: string, tsKundeId: string) => {
            await axios.delete(`${baseUrl}/BackgroundImages/${fileName}?tsKundeId=${tsKundeId}`);
        },
    }
}
