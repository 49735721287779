interface Props {
    tableData: ProductData[];
}

export interface ProductData {
    produktnavn: string
    epdNr: string
    basegtins: string[]
    unitSmallPreviewImageGuid: string | undefined
    strekkodeBase64Url?: string
    angle: string | undefined
}

const ProductTable = (props: Props) => {

    const color = "#000";
    return (
        <table style={{ backgroundColor: '#fff', width: '100%', borderCollapse: 'collapse', fontSize: '13px', color: color, borderColor: color}}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid', padding: '8px' }}>Produktnavn</th>
                    <th style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}>EPD Nr</th>
                    <th style={{ border: '1px solid', padding: '8px 16px', textAlign: 'center', width: '120px' }}>Antall</th>
                    <th style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}>Strekkode</th>
                </tr>
            </thead>
            <tbody>
                {props.tableData.map((row, index) => (
                    <tr key={index}>
                        <td style={{ border: '1px solid', padding: '8px' }}>{row.produktnavn}</td>
                        <td style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}>{row.epdNr}</td>
                        <td style={{ border: '1px solid', padding: '8px 16px', textAlign: 'center', width: '120px' }}></td>
                        <td style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}>
                            {row.strekkodeBase64Url && <img src={row.strekkodeBase64Url} alt="barcode" />}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>);
}

export default ProductTable;