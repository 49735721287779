import { Col, Row } from 'react-bootstrap';
import './index.scss';
import { IcebergButton, useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';
import RightCol from './Components/RightCol';
import { useEffect, useState } from 'react';
import LeftCol, { Template, Templates } from './Components/LeftCol';
// import html2pdf from 'html2pdf.js';
import { toPng } from "html-to-image";
import jsPDF from 'jspdf';
import { ContactInfo, DesignData, TemplateData } from './TemplateData';
import Template1 from './templates/Template1';
import { ProductData } from '../../components/ProductTable';
import { useMsal } from '@azure/msal-react';
import { generateBarcode } from '../../Shared/BarcodeGenerator';

interface Props {
}

const Designer = () => {

    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    const { selectedTsKunde } = useSelectedTsKunde();
    
    const [templateData, setTemplateData] = useState<TemplateData>({
        designData: {
            title: 'Eksempel tittel',
        },
        productData: [],
        contactInfo: {
            email: email ?? '',
            name: name ?? '',
            phone: localStorage.getItem('phoneNumber') || '',
        }
    });

    // reset product data and logo image when selectedTsKunde changes
    useEffect(() => {
        setTemplateData({ ...templateData, productData: [], designData: { ...templateData.designData, logoFileName: undefined } });
    }, [selectedTsKunde]);

    const handleDesignDataChanged = (data: DesignData): void => {
        setTemplateData({ ...templateData, designData: data }); 
    }

    /* const htmlToPdf = (event: any): void => {
        // Access the html element
        const templateElement: any = document.getElementById('template');

        const opt = {
            margin:       0,
            filename:     'document.pdf',
            image:        { type: 'png' },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
          };

        // Use html2pdf to create a PDF from the content
        html2pdf()
            .set(opt)
            .from(templateElement)
            .save();
    } */

    const htmlToImageToPdf = (event: any): void => {
        // Access the html element
        const templateElement: any = document.getElementById('template');

        // to get satisfactory resolution, we need to scale the canvas
        const scale = 3;

        toPng(templateElement, { canvasHeight: templateElement.offsetHeight * scale, canvasWidth: templateElement.offsetWidth * scale })
        .then((png) => {
            try {
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: [templateElement.offsetWidth, templateElement.offsetHeight]
                });
    
                pdf.addImage(png, 'PNG', 0, 0, templateElement.offsetWidth, templateElement.offsetHeight);
                pdf.save('document.pdf');
            } catch (error) {
                console.error('Error generating PDF:', error);
            }
        });
    }

    const [selectedTemplate, setSelectedTemplate] = useState<Template>(Templates.find(o => o.id === 1) as Template);

    const handleTemplateSelected = (o: Template): void => {
        setSelectedTemplate(o);
    }

    const handleProductDataChanged = (data: ProductData[]): void => {
        const data2 = data.map((d, index) => {
            if (!d.strekkodeBase64Url){
                d.strekkodeBase64Url = generateBarcode(d.epdNr);
            }
            return d;
        });
        setTemplateData({ ...templateData, productData: data2 });
    }

    const handleContactInfoChanged = (contactInfo: ContactInfo): void => {
        setTemplateData({ ...templateData, contactInfo: contactInfo });
    }

    const handleAngleSelected = (gtin: string, angle: string): void => {

        // update the angle for the product with the given gtin
        const productData = templateData.productData.map((product) => {
            if (product.basegtins[0] === gtin) {
                product.angle = angle;
            }
            return product;
        });
        setTemplateData({ ...templateData, productData: productData });
    }

    return (
        <div>
            <Row className='mt-3 mb-3'>
                <Col>
                    <h3>Designer - <span style={{color: "red"}} title='Under utvikling'>(beta, under utvikling)</span></h3>
                </Col>
                <Col className='text-end'>
                    <IcebergButton icon='export' variant='outline-primary' onClick={htmlToImageToPdf}>Last ned PDF</IcebergButton>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <LeftCol 
                        onTemplateSelected={handleTemplateSelected} 
                        onProductDataChanged={handleProductDataChanged} 
                        productData={templateData.productData}
                        onAngleSelected={handleAngleSelected}
                    />
                </Col>
                <Col className='template-col' align="center">
                    {templateData && <Template1 templateId={selectedTemplate?.id} templateData={templateData} />}
                </Col>
                <Col md={2}>
                    <RightCol onDesignDataChanged={handleDesignDataChanged} contactInfo={templateData.contactInfo} onContactInfoChanged={handleContactInfoChanged} />
                </Col>
            </Row>
        </div>
    );
}

export default Designer;
