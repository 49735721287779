import Sidebar, { SubLink, NavLink } from ".";

const SidebarWrapper = () => {


    const navLinks: NavLink[] = [
        { label: 'Designer', link: '/', icon: 'company' },
        { label: 'Barcode playground', link: '/barcode', icon: 'barcode' },
        { label: 'Table playground', link: '/tablegenerator', icon: 'table' },

    ];

    return <Sidebar navLinks={navLinks} />;
};


export default SidebarWrapper;