import { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PicassoApi, { LogoDto } from '../../../../Services/PicassoApi';
import { IcebergButton, useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';
import FileUploader from '../FileUploader';
import ConfirmButton from '../../../../components/ConfirmButton';


interface Props {  
  onSave: (logoFileName?: string) => void;
  selectedLogoFileName?: string
}

function LogoModal(props: Props) {

  const {selectedTsKunde } = useSelectedTsKunde();
  const [show, setShow] = useState(false);
  const initialRender = useRef(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [logos, setLogos] = useState<LogoDto[]>([]);
  const [logoUrls, setLogoUrls] = useState<{ [key: string]: string }>({});  
  const [selectedLogo, setSelectedLogo] = useState<LogoDto>();

  const getLogos = async () => {
    const logosResponse = await PicassoApi.logos.get(selectedTsKunde?.tsKundeId);
    setLogos(logosResponse ?? []);
  };
  
  const getLogo = async (fileName: string) => {
    const logoBlob = await PicassoApi.logos.getLogo(fileName, selectedTsKunde?.tsKundeId);
    if(logoBlob){
      const logoUrl = URL.createObjectURL(logoBlob);
      setLogoUrls(prevState => ({ ...prevState, [fileName]: logoUrl }));
    }    
  };

  useEffect(() => { 
    getLogos();
  }, []);

  useEffect(() => {
    logos.forEach(logo => {
      getLogo(logo.fileName);
      if(props.selectedLogoFileName === logo.fileName){
        setSelectedLogo(logo);
      }
    });
  }, [logos]);
  
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    setSelectedLogo(undefined);
    getLogos();
    localStorage.removeItem('selectedLogoFileName');
}, [selectedTsKunde]);

  const handleSavedLogo = () => {    
    if(selectedLogo){
      props.onSave(selectedLogo.fileName);
      localStorage.setItem('selectedLogoFileName', selectedLogo.fileName);      
    }
    else{
      props.onSave(undefined);
      localStorage.removeItem('selectedLogoFileName');
    }
    setShow(false);
  }
  
  const toggleSelection = (logo: LogoDto) => { 
    if(selectedLogo?.fileName === logo.fileName) {
      setSelectedLogo(undefined);
      return;
    }
    setSelectedLogo(logo);
  }

  const deleteLogo = async (logo: LogoDto) => {
    await PicassoApi.logos.delete(logo.fileName, selectedTsKunde?.tsKundeId);
    await getLogos();
  };
  
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);

  const handleLogoUpload = async (file: File): Promise<void> => {
    setIsUploading(true);  
    setIsImageUploaded(false);
    await PicassoApi.logos.post(file, selectedTsKunde?.tsKundeId);
    setIsImageUploaded(true);    
    await getLogos();
    setIsUploading(false);
  };

  return (
    <>
      <div className="d-grid">
      <IcebergButton variant="outline-primary" icon='edit' onClick={handleShow}>
        Legg til logo
      </IcebergButton>
      </div>

      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Velg logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="row mb-4">
            <Col className='col-8'>
            <p>Her kan du laste opp logoer og velge den du ønsker. Bruk unike navn for å unngå overskriving</p>
            </Col>
            <Col className='text-end'>
              <FileUploader
                    handleFile={(file: File) => handleLogoUpload(file)}
                    isImageUploaded={isImageUploaded}
                    uploading={isUploading}
                    title={'Last opp logo'}
                  />
            </Col>            
          </Row> 
          <Table>
            <thead>
              <tr>
                <th className='text-center'>Bilde</th>
                <th>Filnavn</th>                
                <th></th>
              </tr>
            </thead>
            <tbody>
            {logos.map((logo, index) => (
              <tr key={index}>
                <td className='text-center'>
                  { <img height={30} src={logoUrls[logo.fileName]} alt={logo.fileName} />}
                </td>
                <td>{logo.fileName}</td>                
                <td className='text-end'>
                  <div className="d-flex justify-content-end">
                    <IcebergButton
                      variant={selectedLogo?.fileName === logo.fileName ? 'outline-secondary' : 'outline-primary'}
                      icon={selectedLogo?.fileName === logo.fileName ? 'check' : 'plus'}
                      onClick={() => toggleSelection(logo)}
                      className="me-2"  
                    >
                      {selectedLogo?.fileName === logo.fileName ? 'Valgt' : 'Velg'}
                    </IcebergButton>
                    <ConfirmButton
                      variant='outline-danger'                      
                      onClick={() => deleteLogo(logo)}
                    >                      
                    </ConfirmButton>
                  </div>
                </td>                
              </tr>
            ))}
            </tbody>
          </Table>
          <div className="row mb-4">
            <div className="col-6">
              <Button variant="outline-secondary" className="w-100" onClick={handleClose}>
                Close
              </Button>
            </div>
            <div className="col-6">
            <Button variant="primary" className="w-100" onClick={handleSavedLogo}>
              Lagre
            </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LogoModal;