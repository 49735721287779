import axios from "axios";
import { ImageSetResponse } from "./models";

const baseUrl = "https://mediastore.tradesolution.no/media";

const MediastoreMediaApi = {
    imageset: {
        getImageSetByGtin: async (gtin: string) => {    
            const url = `${baseUrl}/imageset/getimagesetsbygtin/${gtin}`;
            return (await axios.get<ImageSetResponse>(url)).data;
        }
    }
};

export default MediastoreMediaApi;