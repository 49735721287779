import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { IcebergProductMenu, IcebergIcon, IcebergCustomerPicker, IcebergNotifications } from '@tradesolution/iceberg-ui-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../Shared/Auth/authConfig';


const MainMenu = () => {

    const { instance, accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;

    const getFirstName = (fullname: string | undefined): string => {
        if (!fullname) {
            return "";
        }
        return fullname.split(' ')[0];
    };

    const handleLogin = (i: IPublicClientApplication) => {
        i.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    const handleLogout = () => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg='info-dark' sticky='top' style={{ padding: "0.2rem 0" }} className='navbar-dark'>
            <Container fluid>
                <Navbar.Brand className='text-decoration-none' href="/">
                    <IcebergIcon icon='picasso' size="lg" />
                    <span className='ms-3'>Picasso</span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />

                <Navbar.Collapse role="navigation">
                    <Nav className="me-auto">
                        <NavDropdown className='noCaret'
                            title={<IcebergIcon icon='meatballs' />}
                        >
                            <IcebergProductMenu />
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <NavDropdown
                            id="dropdownUserMenu"
                            align="end"
                            title={
                                <span style={{ marginLeft: '0.5em' }}>{getFirstName(name)}</span>
                            }
                        >
                            <NavDropdown.Item onClick={handleLogout}>
                                <span>
                                    <strong>Logg av</strong>
                                </span>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <IcebergCustomerPicker />
                        <IcebergNotifications />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
}

export default MainMenu;