import { IcebergButton, IcebergIcon, useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';
import { useEffect, useState } from 'react';
import { Accordion, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { ContactInfo, DesignData } from '../../TemplateData';
import ContactInfoSection from './ContactInfoSection';
import LogoModal from '../LogoModal';
import AdvancedColorPicker from '../AdvancedColorPicker';
import CustomDesignSection from './CustomDesignSection';
import BackgroundImageModal from '../BackgroundImageModal';

interface Props {
  onDesignDataChanged: (data: DesignData) => void;
  contactInfo: ContactInfo;
  onContactInfoChanged: (contactInfo: ContactInfo) => void;
}

const 
RightCol = (props: Props) => {

  const { selectedTsKunde } = useSelectedTsKunde();

  const [designData, setDesignData] = useState<DesignData>({
    title: 'Eksempel tittel',
    logoText: selectedTsKunde?.tsKundeNavn ?? 'Logo',
    fontFamily: localStorage.getItem('fontFamily') || 'Roboto',    
    logoFileName: localStorage.getItem('selectedLogoFileName') || undefined,
    fontColor: localStorage.getItem('fontColor') || '#000000',
    backgroundColor: localStorage.getItem('backgroundColor') || '#FFFFFF',    
  });

  useEffect(() => {
    props.onDesignDataChanged(designData);
  }, [designData]);

  useEffect(() => {    
    setDesignData({ ...designData, logoText: selectedTsKunde?.tsKundeNavn ?? 'Logo' });
}, [selectedTsKunde]);

  //Farge-alternativer
  const backgroundColors = ['#FFFFFF', '#CCEEC9', '#BFE0FF', '#FCD7AD', '#FAC1C0', '#000000'];
  const fontColors = ['#FFFFFF', '#32872C', '#0075DB', '#F58F18', '#B4110F', '#000000'];

  //Font-alternativer
  const fonts = ["Roboto", "Open Sans", "Montserrat", "Verdana", "Times New Roman", "Lato", "Poppins"];

  //Advanced color picker
  const [showColorPicker, setShowColorPicker] = useState(false);


  // Handler for setting background color
  const handleBackgroundColorChange = (color: string) => {
    setDesignData({ ...designData, backgroundColor: color });
    localStorage.setItem('backgroundColor', color); 
  };

  // Handler for setting font color
  const handleFontColorChange = (color: string) => {
    setDesignData({
      ...designData,
      fontColor: color
    });
    localStorage.setItem('fontColor', color);
  };

  // Handler for setting font family
  const handleFontChange = (font: string) => {
    setDesignData({ ...designData, fontFamily: font });
    localStorage.setItem('fontFamily', font);
  };

  async function handleLogoSaved(logoFileName?: string): Promise<void> {
    setDesignData({ ...designData, logoFileName: logoFileName });    
  }

  async function handleBackgroundImageSaved(url?: string): Promise<void> {
    setDesignData({ ...designData, backgroundImageUrl: url });    
  }

  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header><IcebergIcon icon="brush" className='me-2' /> Farger og bokstaver</Accordion.Header>
          <Accordion.Body>
            <Form>
              {/* Background color */}
              <Form.Group className="mb-3" controlId="formBackgroundColor">
                <Form.Label>Velg bakgrunnsfarge</Form.Label>
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                  {backgroundColors.map((color, index) => (
                    <div
                      key={index}
                      onClick={() => handleBackgroundColorChange(color)}
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: color,
                        border: designData.backgroundColor === color ? '2px solid blue' : '2px solid #ddd',
                        cursor: 'pointer',
                      }}
                    />
                  ))}
                  {/* Multicolor button */}
                  <div
                      onClick={() => setShowColorPicker(true)} // Opens the color picker popup
                      style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          background: 'conic-gradient(#FF0000, #FF7F00, #FFFF00, #00FF00, #0000FF, #4B0082, #9400D3)',
                          border: '1px solid #ddd',
                          cursor: 'pointer',
                      }}
                  />
                  {/* Popup for AdvancedColorPicker */}
                  {showColorPicker && (
                      <div style={{
                          position: 'fixed',
                          top: '10%',              
                          right: '20px', 
                          zIndex: 1000,
                          backgroundColor: '#fff',
                          padding: '10px',
                          borderRadius: '8px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      }}>
                          <AdvancedColorPicker onColorChange={handleBackgroundColorChange} />
                          <IcebergButton variant="primary" onClick={() => setShowColorPicker(false)}>
                              Lukk
                          </IcebergButton>
                      </div>
                  )}
                </div>
              </Form.Group>

              {/* Font Color Selection */}
              <Form.Group className="mb-3" controlId="formFontColor">
                <Form.Label>Velg skriftfarge</Form.Label>
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                  {fontColors.map((color, index) => (
                    <div
                      key={index}
                      onClick={() => handleFontColorChange(color)}
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: color,
                        border: designData.fontColor === color ? '2px solid blue' : '2px solid #ddd',
                        cursor: 'pointer',
                      }}
                    />
                  ))}
                </div>
              </Form.Group>

              {/* Font family selection */}
              <Form.Group className="mb-3" controlId="formFontFamily">
                <Form.Label>Velg skrifttype</Form.Label>
                <DropdownButton
                  id="font-family-dropdown"
                  title={designData.fontFamily || "Skrifttype"}
                  onSelect={(font) => handleFontChange(font || '')}
                  variant="outline-primary"
                >
                  {fonts.map((font, index) => (
                    <Dropdown.Item key={index} eventKey={font}>{font}</Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* Innhold */}
      <Accordion defaultActiveKey="0" className='mt-3'>
        <Accordion.Item eventKey="0">
          <Accordion.Header><IcebergIcon icon="company" className='me-2' /> Innhold</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3">
              <Form.Label>Logo</Form.Label>
              <Form.Control className='mb-2' type="text" placeholder="Logotekst" value={designData.logoText} onChange={e => setDesignData({ ...designData, logoText: e.target.value })} />              
              <LogoModal onSave={handleLogoSaved} selectedLogoFileName={designData.logoFileName}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tittel</Form.Label>
              <Form.Control type="text" placeholder="Enter title" value={designData.title} onChange={e => setDesignData({ ...designData, title: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Bakgrunnsbilde</Form.Label>
              <BackgroundImageModal onSave={handleBackgroundImageSaved} selectedUrl={designData.backgroundImageUrl}/>
            </Form.Group>              
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* Kontakt info */}
      <ContactInfoSection contactInfo={props.contactInfo} onContactInfoChanged={props.onContactInfoChanged} />

      {/* Custom design */}
      <CustomDesignSection />
    </div>
  );
}

export default RightCol;