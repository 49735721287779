import { IcebergButton } from '@tradesolution/iceberg-ui-react';
import { ChangeEvent, useRef } from 'react';

interface Props {
  handleFile: (fileUploaded: any) => void
  title: string;
  isImageUploaded: boolean; 
  uploading: boolean;  //ny prop bilde lastes opp
}

const FileUploader = (props: Props) => {
  const hiddenFileInput = useRef<any>(null);

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();    
    event.stopPropagation();    
    if (event.target.files) {
      const fileUploaded = event.target.files[0];
      props.handleFile(fileUploaded);
    }
  }

  return (
    
    <div>
      <IcebergButton icon="camera" variant='outline-primary' title={props.title ?? 'Last opp bilde'} onClick={e => handleClick(e)}>{props.title}</IcebergButton>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
    </div>

  );
};

export default FileUploader;

